import {
  Avatar,
  Chip,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  useMediaQuery,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { FiArchive } from "react-icons/fi";
import React, { useRef, useState, useEffect } from "react";
import FormNoteModal from "../../components/FormNoteModal/FormNoteModal";
import MUIDataTable from "mui-datatables";
import { deleteCandidatFromSelection, getAllCandidatsInSelection } from "../Services/SelectionCandidatService";
import { useParams, Link as LinkDom } from "react-router-dom";
import {
  AccessAlarm,
  Delete,
  LinkedIn,
  RemoveRedEye,
  Send,
  Search,
  Update,
  AddBox,
  NotesOutlined
} from "@mui/icons-material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { v4 } from "uuid";
import { theme } from "../../utils/Theme";
import { useLocation } from 'react-router-dom';
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import { IoMdReturnLeft } from "react-icons/io";
import { displaySwipeableDrawerSummaryCandidat } from "../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch, useSelector } from "react-redux";
import {getRowData, getUserData, useQuery} from "../../utils/FunctionHelpers";
import moment from "moment";
import { setToggleMailing } from "../../redux/toggle/actions/toggleActions";
import ShowAddCvCandidat from "../Candidat/VisualiserCandidat/ShowAddCvCandidat";
import Link from "@mui/material/Link";
import { handleOnViewColumnsChange, displayColumn } from "../../utils/viewColumns";
import { removeLineBreak } from "../../utils/EditorUtil";
import {addNoteCandidat} from "../Services/NoteCandidat";
import SelectionModal from "../Dashboard/Modals/SelectionModal";
import ChooseMultipleSelectionModal from "./Modals/ChooseMultipleSelectionModal";
import {withSnackbar} from "../../utils/SnackbarHOC";
import {makeStyles} from "@mui/styles";
import {SetSelectedCandidatBeAddToSelection} from "../../redux/candidats/actions/candidatsActions";
import CustomToolbarSelect from "../Candidat/ListCandidats/CandidatTable/CustomToolbarSelect";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  taskPassed: {
    color: "firebrick"
  },
  taskToday: {
    color: "blue"
  },
  taskFuture: {
    color: "green"
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  }
}));

const CandidatsListInSelection = ({ history, snackbarShowMessage }) => {
  const [loading, setLoading] = useState(false);
  const [candidats, setCandidats] = useState([]);
  const [reloadSelections, setReloadSelections] = useState(false);
  const { idSelection } = useParams();
  let query = useQuery();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [candidatInfos, setCandidatInfos] = useState({ uuid: "", nom: "", prenom: "" });
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [candidatsCount, setCandidatsCount] = useState(0);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const location = useLocation();
  const mounted = useRef(true);
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openModalSelection, setOpenModalSelection] = useState(false);
  const [errors, setErrors] = useState({});
  const [pj, setPj] = useState(null);
  const [selectedCandidatId, setSelectedCandidatId] = useState(null);
  const [candidatIds, setCandidatIds] = useState([]);
  const selectedCandidatForSelection = useSelector(
      (state) => state.candidats.candidatsSelectedForSelection
  );

  const handleOpenNoteModal = (uuid) => {
    setSelectedCandidatId(uuid)
    setOpenModal(true);
  };

  const handleCloseNoteModal = () => {
    setOpenModal(false);
    setErrors({});
    setPj(null);
  };

  const handleSubmitNote = async (comments) => {
    if (!comments || comments.trim() === "") {
      setErrors({commentaire: "Le champ 'COMMENTAIRE' est obligatoire."});
      return;
    }

    const formData = new FormData();
    formData.append("dateTime", new Date());
    formData.append("commentaire", removeLineBreak(comments));
    formData.append("auteur", getUserData().uuid);
    formData.append("candidat", selectedCandidatId);
    formData.append("pj", pj);

    try {
      snackbarShowMessage("Patientez svp!", "warning", null)
      const response = await addNoteCandidat(formData);
      snackbarShowMessage(response.data)
    } catch (e) {
      snackbarShowMessage("Une erreur est survenue lors d'ajout d'une note" , "error", 4000)
    } finally {
      setLoading(false);
      handleCloseNoteModal();
    }
  };

  const handleOpenDialog = (params) => {
    setCandidatInfos({ uuid: params.rowData[0], nom: params.rowData[3], prenom: params.rowData[2] });
    setOpenDialogDelete(true);
  };

  const viewCandidat = (params, openInNewTab = false) => {
    let candidatsIds = [];
    const currentIndex = history.location.state?.candidatIndex || 0;
    localStorage.setItem("currentPageSelection", page)
    candidats.forEach((candidat) => {
      candidatsIds.push(candidat.id);
    });

    const stateData = {
      currentIndex: currentIndex,
      candidatsInList: candidatsIds,
      candidatIndex: candidatsIds.indexOf(params.rowData[0]),
      selectionName: location.state?.selectionName,
      numbersTotalOfSelections: candidats.length,
    };

    const url = `/selections/${idSelection}/candidats/${params.rowData[0]}/view`;

    if (openInNewTab) {
      localStorage.setItem('viewCandidatState', JSON.stringify(stateData));
      window.open(url, '_blank');
    } else {
      history.push({
        pathname: url,
        state: stateData,
      });
    }
  };
  const handleOpenAddSelection = () => {
    setOpenModalSelection(!openModalSelection);
  }
  const handleDeleteCandidat = () => {
    (async () => {
      setLoadingToDelete(true);

      const res = await deleteCandidatFromSelection(candidatInfos.uuid, idSelection);
      if (!mounted.current) {
        return;
      }
      setLoadingToDelete(false);
      snackbarShowMessage(res.data);
      handleCloseDialog();
      setReloadSelections(!reloadSelections);
    })();
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleTasksClasse = (value) => {
    switch (value) {
      case 3:
        return classes.taskFuture;
      case 2:
        return classes.taskToday;
      case 1:
        return classes.taskPassed;
      default:
        break;
    }
  };

  const handlePopoverOpen = (event, index) => {
    setCandidats((prevCandidats) => {
      const newState = prevCandidats.map((candidat, indexC) => {
        if (indexC === index) {
          return { ...candidat, anchorEl: event.currentTarget };
        }
        return candidat;
      });
      return newState;
    });
  };

  const handlePopoverClose = (index) => {
      setCandidats((prevCandidats) => {
        const newState = prevCandidats.map((candidat, indexC) => {
          if (indexC === index) {
            return { ...candidat, anchorEl: null };
          }
          return candidat;
        });
        return newState;
      });
  };

  const PopoverComponent = ({ selections, candidat }) => {
    return (
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={Boolean(candidat.anchorEl)}
        anchorEl={candidat.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List>
          {selections?.map((selection) => (
            <ListItem key={v4()} dense={true} disableGutters={true}>
              <ListItemAvatar>
                <Avatar>
                  <FiArchive />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={selection?.nom} />
            </ListItem>
          ))}
        </List>
      </Popover>
    );
  };

  useEffect(() => {
    const savedPage = localStorage.getItem("currentPageSelection");
    if (savedPage) {
      setPage(parseInt(savedPage, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentPageSelection", page);
  }, [page]);


  useEffect(() => {
    if (idSelection === "undefined") {
      history.push({
        pathname: "/selections",
      });
    }
    (async () => {
      setLoading(true);
      try {
        setCandidats([]);
        const { data } = await getAllCandidatsInSelection(idSelection);
        setCandidats(data.candidats);
        setCandidatsCount(data.count);
        setLoading(false);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement de données.", "error", 4000);
        setLoading(false);
      }
    })();
  }, [reloadSelections, idSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowChangeType = (statut) => {
    switch (statut) {
      case 'Update':
        return <Update color="primary" />;
      case 'New':
        return <FiberNewIcon color="error" />;
      default:
        return "-";
    }
  };

  const tableName = "candidatListInSelection";

  const columns = [
    {
      name: "id",
      label: "N°CANDIDAT",
      options: {
        filter: true,
        sort: true,
        display: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
      },
    },
    {
      name: "numCandidat",
      label: "N°",
      options: {
        display: displayColumn("numCandidat", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta) => {
          const candidatName = `${tableMeta.rowData[2]} ${tableMeta.rowData[3]}`;
          const files = tableMeta.rowData[16] || [];
          return (
              <ShowAddCvCandidat  {...{ snackbarShowMessage, files, fileName: candidatName }}>
                {value}
              </ShowAddCvCandidat>
          );
        },
      },
    },
    {
      name: "prenom",
      label: "PRENOM",
      options: {
        display: displayColumn("prenom", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "200px" } }),
        customBodyRender: (value, tableMeta) => {
          return (
              <>
                {!isSmMode && (
                    <IconButton
                        onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                        data-toggle="tooltip"
                        title="Voir resumé"
                    >
                      <RemoveRedEye size={"15px"} />
                    </IconButton>
                )}
                <Link
                    component="button"
                    onClick={(e) => {
                      const openInNewTab = e.ctrlKey || e.metaKey;
                      viewCandidat(tableMeta, openInNewTab);
                    }}
                    style={{ color: "rgb(55 156 255)", textDecoration: "none" }}
                >
                  {value}
                </Link>
              </>
          );
        },
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        display: displayColumn("nom", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
              <Link
                  component="button"
                  onClick={(e) => {
                    const openInNewTab = e.ctrlKey || e.metaKey;
                    viewCandidat(tableMeta, openInNewTab);
                  }}
                  style={{ color: "rgb(55 156 255)", textDecoration: "none" }}
              >
                {value}
              </Link>
          );
        },
      },
    },
    {
      name: "updatedAt",
      label: "DATE MAJ",
      options: {
        display: displayColumn("updatedAt", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", with: "100%" } }),
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", textAlign: "center", with: "100%" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <Box>
                {value != null ? moment(value).format("DD/MM/yyyy") : "-"}
            </Box>
          );
        }
      }
    },
    {
      name: "seniorite",
      label: "XP",
      options: {
        display: displayColumn("seniorite", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center", width: "200px" } }),
        customBodyRender: (value) => {
          return <Box>{value != null ? value : 0}</Box>;
        },
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        display: displayColumn("fonction", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { overflow: "hidden", minWidth: "300px", textOverflow: "ellipsis", whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", minWidth: "300px" } }),
        customBodyRender: (value) => {
          return (
            <div style={{ width: '100%', whiteSpace: 'wrap' }} title={value}>
              {value}
            </div>
          );
        },
      },
    },
    {
      name: "telephone",
      label: "MOBILE",
      options: {
        display: displayColumn("telephone", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        display: displayColumn("email", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "200px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "200px" } }),
      },
    },
    {
      name: "nom_societe",
      label: "SOCIETE",
      options: {
        display: displayColumn("nom_societe", tableName),
        filter: true,
        sort: true,
      },
    },
    {
      name: "linkedin",
      label: "LINKEDIN",
      options: {
        display: displayColumn("linkedin", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
              <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                {value && (
                    <a href={value} target="_blank" rel="noreferrer">
                      <LinkedIn />
                    </a>
                )}
              </Box>
          );
        },
      },
    },
    {
      name: "selections",
      label: "VIVIERS",
      options: {
        display: displayColumn("selections", tableName),
        filter: true,
        sort: true,
        sortCompare: (order) => (a, b) => {
          const lengthA = (a.data && a.data.length) || 0;
          const lengthB = (b.data && b.data.length) || 0;
          return (lengthA - lengthB) * (order === 'asc' ? 1 : -1);
        },
        customHeadLabelRender: (columnMeta) => {
          return (
            <Chip
              avatar={
                <Search
                  alt={columnMeta.label}
                  src="/static/images/avatar/1.jpg"
                />
              }
              label={columnMeta.label}
              variant="outlined"
            />
          );
        },
        setCellHeaderProps: () => ({
          style: {
            textAlign: "center",
          },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value.length <= 0 ? (
            <div
              aria-haspopup="true"
              data-toggle="tooltip"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "80%",
                display: "flex",
                justifyContent: "center",
              }}
              onMouseEnter={(event) =>
                handlePopoverOpen(event, tableMeta.rowIndex)
              }
              onMouseLeave={() => handlePopoverClose(tableMeta.rowIndex)}
            >
              {value.length}
            </div>
          ) : (
            <>
              <LinkDom
                to={{
                  pathname: `/candidats/${tableMeta.rowData[4]}/view`,
                }}
                onClick={(e)=>{
                  e.preventDefault();
                  const openInNewTab = e.ctrlKey || e.metaKey;
                  viewCandidat(tableMeta, openInNewTab)
                }}
                aria-haspopup="true"
                data-toggle="tooltip"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onMouseEnter={(event) =>
                  handlePopoverOpen(event, tableMeta.rowIndex)
                }
                onMouseLeave={() => handlePopoverClose(tableMeta.rowIndex)}
              >
                {value.length}
                <PopoverComponent
                  selections={value}
                  candidat={candidats[tableMeta.rowIndex]}
                />
              </LinkDom>
            </>
          );
        },
      },
    },
    { name: "consultant", label: "TALENT MANAGER", options: { filter: true, display: displayColumn("consultant", tableName), sort: true } },
    { name: "sourceur", label: "SOURCEUR", options: { filter: true, display: displayColumn("sourceur", tableName), sort: true } },
    {
      name: "changeType",
      label: "STATUT",
      options: {
        display: displayColumn("changeType", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {handleShowChangeType(value)}
            </>
          );
        },
      },
    },
    {
      name: "task", label: "TACHES", options: {
        display: displayColumn("task", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              {
                value !== 0
                  ? (
                    <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "60px", textAlign: "center" }}>
                      <AccessAlarm f className={handleTasksClasse(value)} />
                    </Box>
                  )
                  : ""
              }
            </>
          );
        },
      }
    },
    { name: "uploadedFiles", label: "uploadedFiles", options: { filter: true, display: false, viewColumns: false } },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        display: displayColumn("actions", tableName),
        filter: false,
        sort: true,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = getRowData(tableMeta, columns);
          return (
              <div style={{position: "relative", right: "20px"}}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <Button data-toggle="tooltip"
                            title="Supprimer" onClick={() => handleOpenDialog(tableMeta)} style={{
                      color: "#EF6262",
                      padding: 0,
                      minWidth: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Delete />
                    </Button>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <ChooseMultipleSelectionModal
                        selectedCandidats={[tableMeta.rowData[0]]}
                        history={history}
                        snackbarShowMessage={snackbarShowMessage}
                        isModify={false}
                        setReloadSelections={setReloadSelections}
                        reloadSelections={reloadSelections}
                        currentSelection={idSelection}
                    >
                      {(handleOpenAffecterSelection) => (
                          <Button data-toggle="tooltip"
                                  title="Ajouter a une selection"
                                  onClick={handleOpenAffecterSelection}
                                  style={{
                                    color: "blue",
                                    padding: 0,
                                    minWidth: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                            <AddBox />
                          </Button>
                      )}

                    </ChooseMultipleSelectionModal>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    <Button data-toggle="tooltip"
                            title="Ajouter une note" onClick={() => handleOpenNoteModal(rowData.id)}  style={{
                      color: "green",
                      padding: 0,
                      minWidth: 'auto',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <NotesOutlined />
                    </Button>
                  </Grid>
                </Grid>
              </div>
          );
        },
      },
    }
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
      if (selectedCandidatForSelection?.length) {
          setCandidatIds(selectedCandidatForSelection);
      }
      }, [selectedCandidatForSelection]);

  const [rowSelecteds, setRowSelecteds] = useState([])
  const [showButtonMailing, setShowButtonMailing] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery(theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery(theme.breakpoints.between(1023, 2800));

  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 299 : isMediumMode ? viewportHeight - 308 : isLargeMode ? viewportHeight - 284 : viewportHeight - 284}px`;

  const [sortOrder, setSortOrder] = useState({ name: "updatedAt", direction: "asc" });
  const handleSortChange = (columnName, direction) => {
    setSortOrder({ name: columnName, direction });
  };

  const options = {
    jumpToPage: true,
    tableBodyMaxHeight: tableBodyMaxHeight,
    page,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    rowsSelected: rowSelecteds,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    selectableRows: "multiple",
    sortOrder: sortOrder,
    onColumnSortChange: handleSortChange,
    customToolbarSelect: (selectedRows) => (
      <CustomToolbarSelect selectedminRows={selectedRows} />
    ),
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    onRowSelectionChange: (rowsSelected, allRowsSelected) => {
      setShowButtonMailing(allRowsSelected.length > 0);

      const selectionsIds = allRowsSelected.map((element) => candidats[element.dataIndex].id);
      const indexes = allRowsSelected.map((element) => element.dataIndex);

      setRowSelecteds(indexes);
      dispatch(SetSelectedCandidatBeAddToSelection(selectionsIds));
    },
    onChangePage: (newPage) => {
      setPage(newPage);
    },
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    open: openDialogDelete,
    handleConfirm: handleDeleteCandidat,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer le candidat "${candidatInfos.nom} ${candidatInfos.prenom}" de la selection`,
    title: "Supprimer le candidat?",
  };


  const candidatsDataCustom = candidats.map((row) => {
    const updatedRow = {
      ...row,
      updatedAt: (row.updatedAt?.date != null) ? moment(row.updatedAt.date).toDate().getTime() : null,
    };
    return updatedRow;
  });

  return (
    <Box sx={{ padding: "0 20px " }}>
      <Box className="candidatedit_header">
        <Grid container spacing={1} direction={isSmMode ? "column-reverse" : "row"} >
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <h4 style={{ color: "rgb(17, 122, 167)" }}>SÉLECTION : {query.get('selectionName') + ' - ' + candidatsCount}</h4>
            <h3 style={{ color: "rgb(17, 122, 167)" }}>CONSULTANT : {query.get('consultant')}</h3>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
              {!isSmMode ? showButtonMailing ?( <>
                        <SelectionModal
                            selectedCandidats={candidatIds}
                            history={history}
                            snackbarShowMessage={snackbarShowMessage}
                            isModify={false}
                            setReloadSelections={setReloadSelections}
                            reloadSelections={reloadSelections}
                        >
                          {(handleOpenAddSelection) => (
                              <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={handleOpenAddSelection}
                                  style={{ marginRight: "10px" }}
                              >
                                Cree une nouvelle sélection
                              </Button>
                          )}
                        </SelectionModal>

                        <Button color="primary" onClick={() => dispatch(setToggleMailing(true))} variant="contained" endIcon={<Send />}>Mailing</Button> </>
                ): <Button
                onClick={() => history.goBack()}
                variant="outlined"
                disabled={loading}
                sx={{
                  color: "#1b2a4e",
                  backgroundColor: "#d9e2ef",
                  borderColor: "#d9e2ef",
                }}
              >
                RETOURNER
              </Button> :
                showButtonMailing ? <IconButton
                  color="primary"
                  onClick={() => dispatch(setToggleMailing(true))}
                  variant="contained"
                  size="medium"
                  data-toggle="tooltip"
                  title="Mailing">
                  <Send /></IconButton> :
                  <IconButton aria-label="Retourner"
                    data-toggle="tooltip"
                    title="Retourner"
                    style={{
                      color: "#1b2a4e",
                      backgroundColor: "#d9e2ef",
                      borderColor: "#d9e2ef",
                    }}
                    size="medium"
                    onClick={() => history.goBack()}>
                    <IoMdReturnLeft fontSize="18px" />
                  </IconButton>}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="candidatedit_container">
        <MUIDataTable title={"Candidats"} data={candidatsDataCustom} columns={columns} options={options} />
            <FormNoteModal
                errors={errors}
                setPj={setPj}
                loading={false}
                handleSubmit={handleSubmitNote}
                modalState={openModal}
                handleOpen={handleOpenNoteModal}
                handleClose={handleCloseNoteModal}
                showAddButton={false}
            />
        <DialogDeleteCustom {...dialogDeleteProps} />
      </Box>
    </Box>
  );
}

export default withSnackbar(CandidatsListInSelection);
