import {
    Box,
    CircularProgress,
    IconButton,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {Link} from "react-router-dom";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { RemoveRedEye } from "@mui/icons-material";
import { displaySwipeableDrawerSummaryCandidat } from "../../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import ShowAddCvCandidat from "../../../Candidat/VisualiserCandidat/ShowAddCvCandidat";
import { handleOnViewColumnsChange, displayColumn } from "../../../../utils/viewColumns";
import AppointmentModal from "../../../Candidat/VisualiserCandidat/Tabs/Modals/CandidatProcess/AppointmentModal";
import { getRowData } from './../../../../utils/FunctionHelpers.js'

const TableReportingIntern = ({ reportingData, snackbarShowMessage, reloadData, setReloadData }) => {
  const dispatch = useDispatch()

  const tableName = "reportingColumnVisibility";

    const columns = [
        {
          name: "id",
          label: "ID ",
          options: { filter: true, sort: true, display: false },
        },
        {
            name: "numCandidat",
            label: "N° CANDIDAT",
            options: {
                display: displayColumn("numCandidat", tableName),
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta) => {
                    const candidatName = `${tableMeta.rowData[2]}`;
                    const uploadedFiles = tableMeta.rowData[tableMeta.rowData.length - 1] || [];
                    return (
                        <ShowAddCvCandidat
                            {...{ snackbarShowMessage, files: uploadedFiles, fileName: candidatName }}
                        >
                            {value}
                        </ShowAddCvCandidat>
                    );
                },
            },
        },
        {
            name: "nom",
            label: "NOM",
            options: {
                display: displayColumn("nom", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const rowData = getRowData(tableMeta, columns);
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                            }}
                        >
                            {!(isSmall || isMediumMode) && <IconButton
                                onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: rowData.id}))}
                                data-toggle="tooltip"
                                title="Voir resumé">
                                <RemoveRedEye size={"15px"} /></IconButton>}
                            <Link
                                to={{ pathname: `/candidats/${rowData.id}/view` }}
                            >
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "sourceur",
            label: "SOURCEUR",
            options: {
                display: displayColumn("sourceur", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "center",
                                width: "140px",
                            }}
                        >
                            {value ?? "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "consultant",
            label: "TALENT MANAGER",
            options: {
                display: displayColumn("consultant", tableName),
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "center",
                                width: "140px",
                            }}
                        >
                            {value ?? "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "manager",
            label: "DELIVERY MANAGER",
            options: {
                display: displayColumn("manager", tableName),
                filter: true,
                sort: true,
                setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "center",
                                width: "140px",
                            }}
                        >
                            {value ?? "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "nom_societe",
            label: "CLIENT",
            options: {
                display: displayColumn("nom_societe", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const rowData = getRowData(tableMeta, columns);
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100px",
                            }}
                        >
                            <Link to={{ pathname: `/societe/${rowData.client_id}/view` }}>
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "nom_mission",
            label: "MISSION",
            options: {
                display: displayColumn("nom_mission", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const rowData = getRowData(tableMeta, columns);
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "200px",
                            }}
                        >
                            <Link
                                to={{
                                    pathname: `/societe/${rowData.client_id}/missions/${rowData.uuid}/view`,
                                }}
                            >
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "priorite",
            label: "PRIORITE",
            options: {
                display: displayColumn("priorite", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", textAlign: "left", width: "150px" }}>
                                {value ?? "-"}
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "lastAppointmentStatus",
            label: "STEP",
            options: {
                display: displayColumn("lastAppointmentStatus", tableName),
                customBodyRender: (value, tableMeta, updateValue) => {
                    const prequalifRh = tableMeta.rowData[tableMeta.columnIndex + 1];
                    const testInterne = tableMeta.rowData[tableMeta.columnIndex + 2];
                    const envoiClient = tableMeta.rowData[tableMeta.columnIndex + 3];
                    const rvClient = tableMeta.rowData[tableMeta.columnIndex + 4];
                    const rvClosing = tableMeta.rowData[tableMeta.columnIndex + 5];
                    const status = value;
                    const expectedStatus = [
                        "Propale",
                        "Embauche/Integration",
                        "REFUSÉ PAR CLIENT",
                        "REFUSÉ PAR CANDIDAT",
                        "REFUS PROPALE"
                    ]
                    let stepValue = "Préquatif RH";

                    if (expectedStatus.includes(status)) {
                        stepValue = value;
                    }
                    else if (rvClosing) {
                        stepValue = "RV CLOSING";
                    } else if (rvClient) {
                        stepValue = "RV CLIENT";
                    } else if (envoiClient) {
                        stepValue = "ENVOI CLIENT";
                    } else if (testInterne) {
                        stepValue = "TEST INTERNE";
                    }

                    return (
                        <Box
                            data-toggle="tooltip"
                            title={stepValue}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "150px"
                            }}
                        >
                            {stepValue}
                        </Box>
                    );
                },
            },
        },
        {
            name: "dateAccrochage",
            label: "PREQUALIF RH",
            options: {
                display: displayColumn("dateAccrochage", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvTech",
            label: "TEST INTERNE",
            options: {
                display: displayColumn("rvTech", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },

        {
            name: "sent",
            label: "ENVOI CLIENT",
            options: {
                display: displayColumn("sent", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvClient",
            label: "RV CLIENT",
            options: {
                display: displayColumn("rvClient", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        {
            name: "rvClosing",
            label: "RV CLOSING",
            options: {
                display: displayColumn("rvClosing", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                            {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                        </Box>
                    );
                },
            },
        },
        /*{
            name: "lastAppointmentDatePropale",
            label: "PROPALE",
            options: {
                display: displayColumn("lastAppointmentDatePropale", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let formattedDate = value && moment(new Date(value)).isValid()
                        ? moment(new Date(value)).format("DD/MM/YYYY")
                        : "-";

                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                                {formattedDate}
                        </Box>
                    );
                },
            },
        },*/
        {
            name: "lastAppointmentDateIntegration",
            label: "EMB/INTÉGRATION",
            options: {
                display: displayColumn("lastAppointmentDateIntegration", tableName),
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let formattedDate;
                    formattedDate = value && moment(new Date(value)).isValid()
                        ? moment(new Date(value)).format("DD/MM/YYYY")
                        : "-";
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textAlign: "left",
                                width: "90px",
                            }}
                        >
                                {formattedDate}
                        </Box>
                    );
                },
            },
        },
        {
            name: "actions",
            label: "ISSUE",
            options: {
                display: displayColumn("actions", tableName),
                setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
                setCellProps: () => ({ style: { padding: "0px 12px" } }),
                customBodyRender: (value, tableMeta) => {
                    const rowData = getRowData(tableMeta, columns);
                    const processId = rowData.idProcess;
                    return (
                        <Box>
                            <AppointmentModal
                                processId={processId}
                                reloadData={reloadData}
                                setReloadData={setReloadData}
                                snackbarShowMessage={snackbarShowMessage}
                            />
                        </Box>
                    );
                },
            },
        },
        {
            name: "uuid",
            label: "N°MISSION",
            options: { filter: true, sort: true, display: false, },
        },
        // {
        //     name: "appointments",
        //     options: { filter: true, sort: true, display: displayColumn("appointments", tableName), },
        // },
        {
            name: "idProcess",
            options: { filter: true, sort: true, display: false, },
        },
        {
            name: "client_id",
            options: { filter: true, sort: true, display: false, },
        },
         {
             name: "uploadedFiles",
             options: { filter: true, sort: true, display: false},
         },
    ];

    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setViewportHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
    const isMediumMode = useMediaQuery((theme) =>
        theme.breakpoints.between(767, 1023)
    );
    const isLargeMode = useMediaQuery((theme) =>
        theme.breakpoints.between(1023, 2800)
    );
    const tableBodyMaxHeight = `${isSmall
        ? viewportHeight - 260
        : isMediumMode
            ? viewportHeight - 550
            : isLargeMode
                ? viewportHeight - 430
                : viewportHeight - 430}px`;

    const options = {
        tableBodyMaxHeight: tableBodyMaxHeight,
        rowsPerPage: 50,
        sort: true,
        textLabels: {
            body: {
                noMatch: reportingData.isLoading ? (
                    <CircularProgress />
                ) : (
                    "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
                ),
            },
        },
        onViewColumnsChange: (columnName, action) => {
          handleOnViewColumnsChange(columnName, action, tableName);
        },
        selectableRows: "none",
        draggableColumns: {
            enabled: false,
        },
        responsive: "standard",
    };

    const reportingDataCustom = reportingData?.data?.map((row) => {
        const updatedRow = {
            ...row,
            sent: (row.sent?.date != null) ? moment(row?.sent?.date).toDate().getTime() : null,
            rvTech: (row.rvTech?.date != null) ? moment(row?.rvTech?.date).toDate().getTime() : null,
            rvClient: (row.rvClient?.date != null) ? moment(row?.rvClient?.date).toDate().getTime() : null,
            rvClosing: (row.rvClosing?.date != null) ? moment(row?.rvClosing?.date).toDate().getTime() : null,
            dateAccrochage: (row.dateAccrochage?.date != null) ? moment(row?.dateAccrochage?.date).toDate().getTime() : null,
            dateIntegration: (row.dateIntegration?.date != null) ? moment(row?.dateIntegration?.date).toDate().getTime() : null,
            revealed: (row.revealed?.date != null) ? moment(row?.revealed?.date).toDate().getTime() : null,
        };
        return updatedRow;
    });

    return (
        <MUIDataTable
            data={reportingDataCustom}
            columns={columns}
            options={options}
        />
    );
};

export default withSnackbar(TableReportingIntern);
