import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Backdrop, Box, Button, Modal, Fade } from '@mui/material';
import { Close, Description } from "@mui/icons-material";
import LoadingButton from '@mui/lab/LoadingButton';
import { withSnackbar } from '../../../utils/SnackbarHOC';
import { getCvBlob } from '../../Services/FileService';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        borderRadius: '20px',
        width: '60%',
        height: '95%',
        zIndex: 100000,
    },
    modalHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1rem',
    },
    closeButton: {
        color: '#1b2a4e',
        backgroundColor: '#d9e2ef',
        marginRight: '0.5em',
        fontWeight: '500',
    },
    modalTitle: {
        color: 'rgb(17 122 167)',
    },
    buttonStyle: {
        color: '#2196f3',
    },
    modalActions: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    },
}));

function ShowAddCvCandidat({ children, snackbarShowMessage, files, fileName, ...otherProps }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [iframe, setIframe] = useState('');
    const searchCv = files?.filter((cv) => cv.categorie === 'cv');

    const handleShowButton = () => (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            {searchCv?.length > 0 ? (
                <LoadingButton
                    data-toggle="tooltip"
                    title={'Ouvrir CV.'}
                    className={classes.buttonStyle}
                    onClick={handleOpen}
                    variant="text"
                    size="medium"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Description />}
                >
                    <span>{children ?? ''}</span>
                </LoadingButton>
            ) : (
                <span>{children ?? 'CV'}</span>
            )}
        </Box>
    );

    const getIframe = async (cv) => {
        const res = await getCvBlob(cv);
        const fileUrl = window.URL.createObjectURL(res.data);
        return (
            <div
                style={{ height: '85%' }}
                dangerouslySetInnerHTML={{
                    __html:
                        `<iframe title="afficher cv" src="${fileUrl}#toolbar=0" width="100%" height="100%" />`,
                }}
            />
        );
    };

    const handleDownload = async () => {
        try {
            const res = await getCvBlob(searchCv[0]?.image_name ?? searchCv[0]?.imageName);
            const fileUrl = window.URL.createObjectURL(res.data);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            snackbarShowMessage('Impossible de télécharger le CV, fichier physique introuvable', 'error', 4000);
        }
    };

    const handleOpen = async () => {
        try {
            setLoading(true);
            const iframe = await getIframe(searchCv[0]?.image_name ?? searchCv[0]?.imageName);
            setIframe(iframe);
            setOpen(true);
        } catch (e) {
            snackbarShowMessage('Impossible de charger le CV, fichier physique introuvable', 'error', 4000);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => setOpen(false);

    const body = (
        <div className={classes.paper}>
            <div className={classes.modalHeader}>
                <h2 className={classes.modalTitle}>CV CANDIDAT</h2>
                <Button className={classes.closeButton} onClick={handleClose}>
                    <Close />
                </Button>
            </div>
            {iframe}
            <div className={classes.modalActions}>
                <Button onClick={handleDownload}>
                    Télécharger le CV
                </Button>
            </div>
        </div>
    );

    return (
        <>
            {handleShowButton()}
            <Modal
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
                open={open}
                onClose={handleClose}
            >
                <Fade in={open}>{body}</Fade>
            </Modal>
        </>
    );
}

export default withSnackbar(ShowAddCvCandidat);